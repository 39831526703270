* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #111;
}

html,
body {
  overflow: hidden;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding: 0;
}

.header > img {
  display: block;
  margin: auto;
  width: 100%;
}

.header > h1 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: sticky;
  bottom: 0;
  height: 50px;
  width: 100%;
  padding: 0.5rem 2rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  margin: 0 6px;
  text-decoration: none;
  text-underline-offset: 0.125em;
}

footer img {
  height: 20px;
  margin: 0;
  transform: translate(0, 4px);
}

h2 a {
  text-decoration: none;
}

.main {
  position: relative;
  height: calc(100dvh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}

.form {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 100%;
}

.form-control {
  padding: 0.75rem 1rem;
  background-color: #1e1e1e;
  border: 1px solid #ffffff1a;
  border-radius: 0.5rem;
  font-size: 16px;
  overflow: clip !important;
}

.form-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: var(--lk-control-fg);
  background-image: none;
  background-color: #1f8cf9;
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.empty-event-list {
  font-size: 32px;
  color: #ffffff80;
  user-select: none;
}

.floating-menu {
  position: absolute;
  left: 1rem;
  top: 0;
  padding: 8px;
  z-index: 99;
}

.floating-control {
  cursor: pointer;
}

.floating-control svg {
  height: 16;
  width: 16;
}

.processing-video {
  position: fixed;
  top: 12px;
  left: 48px;
  height: 24px;
  background-color: #00000080;
  color: #ffffff80;
  font-size: 0.8rem;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  z-index: 99;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: calc(100dvh - 119px);
  background-color: #00000080;
  backdrop-filter: blur(8px);
  z-index: 999;
}

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 600px;
  padding: 16px !important;
  margin: 0 !important;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #1e1e1e;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: all;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e;
  color: #ffffff;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: all;
}

/* .modal::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  backdrop-filter: blur(4px);
  z-index: 3;
} */

.modal .modal-video {
  padding: 0 !important;
  margin: 0 !important;
  max-height: calc(100vh - 156px);
  max-width: 98vw !important;
  object-fit: contain;
}

.modal-title {
  position: absolute;
  width: 100%;
  padding: 8px 16px;
  margin: 0;
  font-size: 24px;
  background-color: #00000080;
  backdrop-filter: blur(8px);
  bottom: 0;
  z-index: 5;
}

@media screen and (max-width: 550px) {
  .modal-title {
    padding: 8px 12px;
    font-size: 18px;
  }
}

.modal-button {
  background-color: transparent;
  color: #1f8cf9;
  border: 0;
  margin: 8px;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #1f8cf9;
  color: #ffffff;
}

.modal-button.danger {
  color: #ff0000;
}

.modal-button.danger:hover {
  background-color: #ff0000;
  color: #ffffff;
}

.lk-video-conference-inner {
  transition: all 0.3s !important;
}

.lk-chat {
  position: relative;
  z-index: 999 !important;
  animation: animate-slide-in 0.3s ease-in-out !important;
  transition: all 0.3s;
}

@keyframes animate-slide-in {
  from {
    width: 0;
    right: -100%;
  }
  to {
    width: clamp(200px, 55ch, 60ch);
    right: 0;
  }
}

.floating-collapse-control {
  position: absolute;
  top: 0.25rem;
  left: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  overflow: hidden;
}

.lk-more-button {
  position: relative !important;
  height: 24px;
  width: 24px;
  border-radius: calc(var(--lk-border-radius) / 2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #00000080 !important;
  overflow: hidden;
  z-index: 2;
}

.floating-collapse-control-wrapper {
  max-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 1;
}

.floating-collapse-control-wrapper.show {
  max-width: 100vw;
}

.lk-participant-media-video {
  border-radius: 0.25rem !important;
  object-fit: contain;
}

.lk-participant-media-video[data-lk-orientation='landscape'] {
  object-fit: contain !important;
}
